// import node_modules
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { Button, Heading, Text } from '@storylinerlabs/design';
import { useTranslation } from 'react-i18next';
import { useImmerAtom } from 'jotai/immer';

// import types
import { SectionColor, SectionColorEnum } from '../../types';

// import components
import Section from '../atoms/Section';

// import modules
import { earlyAccessModalAtom } from '../../modules/jotai';

// import styles
import styles from './EarlyAccessSection.module.css';

// define component
const EarlyAccessSection: React.FunctionComponent<{
  bgColor?: SectionColor;
}> = ({ bgColor }) => {
  // init hooks
  const [, setEarlyAccessModalState] = useImmerAtom(earlyAccessModalAtom);
  const { t } = useTranslation('components');

  // init func
  const onOpenModal = React.useCallback(() => {
    setEarlyAccessModalState(() => true);
  }, [setEarlyAccessModalState]);

  // render section
  return (
    <Section bgColor={bgColor}>
      <div className={styles['storyliner-early-access-section']}>
        <div className={styles['storyliner-early-access-section-header']}>
          <Heading alignment="center" isHyphenated={false} tag="h2">
            {t('EarlyAccessSection.title')}
          </Heading>
          <Text alignment="center">{t('EarlyAccessSection.intro')}</Text>
        </div>

        <div className={styles['storyliner-early-access-button']}>
          <Button
            color="dark"
            text={t('EarlyAccessSection.callToAction.label')}
            size="large"
            onClick={onOpenModal}
          />

          <div className={styles['storyliner-early-access-scribble']} />
        </div>

        <div className={styles['storyliner-early-access-section-helptext-container']}>
          <Text alignment="center" isHelpText size="small">
            {t('EarlyAccessSection.creditCard')}
          </Text>
        </div>
      </div>
    </Section>
  );
};

EarlyAccessSection.defaultProps = {
  bgColor: 'white',
};

EarlyAccessSection.propTypes = {
  bgColor: PropTypes.oneOf<SectionColorEnum>(Object.values(SectionColorEnum)),
};

export default EarlyAccessSection;
